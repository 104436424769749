//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { STable, XCard } from '@/components'
import { getAppPage, sysAppDelete, sysAppSetAsDefault } from '@/api/modular/system/appManage'
import { sysDictTypeDropDown } from '@/api/modular/system/dictManage'
import editForm from './editForm'
import addForm from './addForm'
export default {
  components: {
    XCard,
    STable,
    editForm,
    addForm
  },
  data () {
    return {
      // description: '面包屑說明',
      // 查詢參數
      queryParam: {},
      // 表頭
      columns: [
        {
          title: '應用名稱',
          dataIndex: 'name'
        },
        {
          title: '唯一編碼',
          dataIndex: 'code'
        },
        {
          title: '是否默認',
          dataIndex: 'active',
          scopedSlots: { customRender: 'active' }
        },
        {
          title: '狀態',
          dataIndex: 'status',
          scopedSlots: { customRender: 'status' }
        }
      ],
      tstyle: { 'padding-bottom': '0px', 'margin-bottom': '10px' },
      // 加載數據方法 必須為 Promise 對象
      loadData: parameter => {
        return getAppPage(Object.assign(parameter, this.queryParam)).then((res) => {
          return res.data
        })
      },
      loading: false,
      statusDict: [],
      activeDict: []
    }
  },
  created () {
    this.sysDictTypeDropDown()
    if (this.hasPerm('sysApp:edit') || this.hasPerm('sysApp:delete') || this.hasPerm('sysApp:setAsDefault')) {
      this.columns.push({
        title: '操作',
        width: '200px',
        dataIndex: 'action',
        scopedSlots: { customRender: 'action' }
      })
    }
  },
  methods: {
    activeFilter (active) {
      // eslint-disable-next-line eqeqeq
      const values = this.activeDict.filter(item => item.code == active)
      if (values.length > 0) {
        return values[0].value
      }
    },
    statusFilter (status) {
      // eslint-disable-next-line eqeqeq
      const values = this.statusDict.filter(item => item.code == status)
      if (values.length > 0) {
        return values[0].value
      }
    },
    /**
     * 獲取字典數據
     */
    sysDictTypeDropDown () {
      sysDictTypeDropDown({ code: 'yes_or_no' }).then((res) => {
        this.activeDict = res.data
      })
      sysDictTypeDropDown({ code: 'common_status' }).then((res) => {
        this.statusDict = res.data
      })
    },
    handleOk () {
      this.$refs.table.refresh()
    },
    sysDefault (record) {
      this.loading = true
      sysAppSetAsDefault({ id: record.id }).then((res) => {
        this.loading = false
         if (res.success) {
           this.$message.success('設置成功')
           this.$refs.table.refresh()
         } else {
           this.$message.error('設置失敗：' + res.message)
         }
      })
    },
    /**
     * 刪除應用
     */
    sysAppDelete (record) {
      this.loading = true
      sysAppDelete(record).then((res) => {
        this.loading = false
        if (res.success) {
          this.$message.success('刪除成功')
          this.$refs.table.refresh()
        } else {
          this.$message.error('刪除失敗：' + res.message)
        }
      }).catch((err) => {
        this.$message.error('刪除錯誤：' + err.message)
      })
    }
  }
}
